import { Component, OnInit } from '@angular/core';
import { AssignJeevesDefaultsComponent, JeevesDeliveryAddressesService } from 'gung-standard-jeeves';
import {
  CustomerContactService,
  SelectedCustomerService,
  Customer,
  CustomerService,
  GungFlowService,
  AuthService,
  CartService,
  AvailabilityService,
  GungFlow,
  CustomerContact,
  CartRow
} from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { environment } from '../../../../environments/environment';
import { merge } from 'lodash-es';

@Component({
  selector: 'app-hl-assign-defaults',
  templateUrl: './hl-assign-defaults.component.html',
  styleUrls: ['./hl-assign-defaults.component.css']
})
export class HlAssignDefaultsComponent extends AssignJeevesDefaultsComponent implements OnInit {
  constructor(
    protected selectedCustomerService: SelectedCustomerService,
    protected customerService: CustomerService,
    protected customerContactService: CustomerContactService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected cartService: CartService,
    protected availabilityService: AvailabilityService,
    protected dateUtilService: DateUtilService,
    protected jeevesDeliveryAddressesService: JeevesDeliveryAddressesService
  ) {
    super(
      selectedCustomerService,
      customerService,
      customerContactService,
      gungFlowService,
      authService,
      cartService,
      availabilityService,
      dateUtilService,
      jeevesDeliveryAddressesService
    );
  }

  protected setValues(
    customer: Customer,
    flow: GungFlow,
    user,
    customerContacts: CustomerContact[],
    billingCustomer?: Customer,
    cartRows?: CartRow[]
  ) {
    super.setValues(customer, flow, user, customerContacts, billingCustomer);
    this.checkout.extra.oh.extordtyp = environment.externalOrderType;

    const isCustNlSuperunie1600 =
      !environment.sales &&
      environment.bolag === '1600' &&
      environment.mainCountry === 'nl' &&
      environment.mainCustomer === 'spar';

    const isManEnCloetta1210 =
      !environment.sales &&
      environment.managers &&
      environment.bolag === '1210' &&
      environment.mainCountry === 'en' &&
      environment.mainCustomer === 'cloetta';

    const isRemaPM =
      !environment.sales &&
      environment.managers &&
      environment.bolag === '1210' &&
      environment.mainCountry === 'no' &&
      environment.subCustomer === '';

    if (!user.roles.includes('ADMIN') && !user.roles.includes('SALES')) {
      this.checkout.extra.oh.kundref2 = user.email;
    }

    /*
      Tredje gången gillt 😊 Franska säljarna får fortfarande bekräftelsemail skickade till sig – kan ni ta bort det?
    */
    if (environment.sales && environment.bolag === '1600') {
      this.checkout.extra.confirmationEmails = [];
    }

    if (!this.checkout.extra.kus) {
      this.checkout.extra.kus = {};
    }
    this.checkout.extra.kus.q_hl_emailtt = customer.extra.kus.q_hl_emailtt || '';
    this.checkout.extra.oh.q_hl_emailtt = '';

    /*
      This should be done in all checkouts, but unsure of consequences right now
    */
    if (user.extra.orderParams) {
      merge(this.checkout.extra, user.extra.orderParams);
    }

    if (environment.sales) {
      this.checkout.extra.kus.q_hl_default_email = customer.extra.kus.q_hl_default_email || '';
      this.checkout.extra.kus.q_hl_mail_invoicecc = customer.extra.kus.q_hl_mail_invoicecc || '';
    }
  }
}
