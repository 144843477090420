import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { filter, first, forkJoin, map, Observable, of, Subscription, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { loadStripe } from '@stripe/stripe-js';
import {
  AuthService,
  BackendFeatureService,
  CalendarDate,
  CartRow,
  CartService,
  CartTotalsService,
  CheckoutObject,
  Customer,
  CustomerService,
  GungModalService,
  MetadataService,
  SelectedCustomerService,
  StripeService,
  TotalObject
} from 'gung-standard';
import {
  DeliveryLocationsService,
  JeevesDeliveryAddressesService,
  JeevesOrderDeliveryAddress
} from 'gung-standard-jeeves';
import { environment } from '../../../environments/environment';
import { User } from 'gung-standard/lib/state/auth/types';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'lodash-es';
import { Location } from '@angular/common';
import { ActionType, HlDisplayMsflowArgumentService } from '../../services/hl-display-msflow-argument.service';
import { HlDisplayCreditBlockedService } from '../../services/hl-display-credit-blocked.service';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
  HlDisplayAdditionalCostTerms,
  HlDisplayAdditionalCostsService
} from '../../services/hl-display-additional-costs.service';

export enum CheckoutInitStates {
  Initializing = 'INITIALIZING',
  Completed = 'COMPLETED',
  Failed = 'FAILED'
}

@Component({
  selector: 'app-hl-checkout',
  templateUrl: './hl-checkout.component.html',
  styleUrls: ['./hl-checkout.component.scss']
})
export class HlCheckoutComponent implements OnInit {
  isCreditHardBlocked = false;
  total: TotalObject;
  selectedCustomer: Customer;
  initStatus: CheckoutInitStates = CheckoutInitStates.Initializing;
  checkoutInitStates: typeof CheckoutInitStates = CheckoutInitStates;
  campaignCode: string;
  discountDescription = '';
  anyCartItemRequestQtyAboveAvailable = false;
  isSales = environment.sales;
  isUkBolag = environment.bolag === '1710';
  isFRCustomerPortalPharma =
    !environment.sales && environment.mainCountry === 'fr' && environment.mainCustomer === 'Pharmacies';
  public isFRCustomerPortal1600General =
    !environment.sales &&
    environment.mainCountry === 'fr' &&
    environment.bolag === '1600' &&
    environment.mainCustomer === 'General';
  isPortalNo = environment.mainCountry === 'no';
  isNOCustomerPortal = !environment.sales && environment.mainCountry === 'no';
  isNOCustomerPortalCoop = false;
  isNOCustomerPortalNorgesGroupAndSpar = false;
  isNOCustomerPortalReitanGroup = false;
  isDeliveryInformationFromDeliveryAddresses = false;
  public isCustNlSuperunie1600 =
    !environment.sales &&
    environment.bolag === '1600' &&
    environment.mainCountry === 'nl' &&
    environment.mainCustomer === 'spar';
  readOnlyPrice: boolean = false;
  checkoutObject: CheckoutObject = {
    rows: [],
    extra: {
      _flow: {
        isPreorder: true
      },
      _isPharmaPortal: this.isFRCustomerPortalPharma
    }
  };
  isPortalManagers = environment.managers === true;
  isCoopPortal = environment.mainCustomer === 'coop';
  isManagersNisa = environment.managers && environment.mainCountry === 'en';
  isCardPaymentCustomer = false;
  submitting = false;
  checkoutForms: FormGroup[];
  deliveryCustomer: Customer;
  billingCustomer: { [s: string]: any };
  public deliveryAddresses: JeevesOrderDeliveryAddress[];
  public deliveryAddressesSelectedIndex = 0;
  isKeskoCustomer =
    !environment.sales &&
    environment.mainCountry === 'fi' &&
    environment.bolag === '1210' &&
    environment.mainCustomer === 'General';
  isCurrysPortal =
    environment.bolag === '1710' &&
    !environment.sales &&
    !environment.managers &&
    environment.mainCountry === 'en' &&
    environment.mainCustomer === '';

  submittedCart: CartRow[];
  private cartSubscribe: Subscription;

  // field errors
  showKundref2Error = false;
  showKundbestnr2Error = false;

  public freight: number; // q_hl_fc_amount
  public minOrderQty: number; // q_hl_minordervalue
  public adminCharge: number; // q_hl_sof_charged_amount
  public franco: number; // q_hl_fc_threshold_amount
  public adminChargeMin: number; // q_hl_sof_threshold_amount
  public freightMin: number; // q_hl_fc_threshold_amount
  public additionalCostsTermsObject: HlDisplayAdditionalCostTerms;

  constructor(
    private http: HttpClient,
    private cartService: CartService,
    private selectedCustomerService: SelectedCustomerService,
    private formBuilder: FormBuilder,
    private cartTotalService: CartTotalsService,
    private authService: AuthService,
    private deliveryLocationService: DeliveryLocationsService,
    private router: Router,
    private stripeService: StripeService,
    private translateService: TranslateService,
    private jeevesDeliveryAddressesService: JeevesDeliveryAddressesService,
    private customerService: CustomerService,
    private metadataService: MetadataService,
    private modalService: GungModalService,
    private additionalCostsService: HlDisplayAdditionalCostsService,
    private backendFeatureService: BackendFeatureService,
    protected location: Location,
    protected hlDisplayMsflowArgumentService: HlDisplayMsflowArgumentService,
    protected creditBlockedService: HlDisplayCreditBlockedService
  ) {
    this.checkoutInitStates = CheckoutInitStates;
    authService
      .getRoles()
      .pipe(
        first(),
        filter(roles => roles.includes('SALES') || roles.includes('ADMIN'))
      )
      .subscribe(_ => router.navigateByUrl('/checkout2'));

    if (this.isNOCustomerPortal) {
      this.setNoCustomerPortal();
    }
  }

  getDisableEmail() {
    return environment.bolag === '1710';
  }

  ngOnInit() {
    this.cartSubscribe = this.cartService
      .getCurrentCart()
      .pipe()
      .subscribe((cart: CartRow[]) => {
        if (!cart || cart.length === 0) {
          this.initStatus = CheckoutInitStates.Failed;
          return;
        } else if (this.initStatus !== CheckoutInitStates.Completed && this.initStatus !== CheckoutInitStates.Failed) {
          this.cartTotalService.getTotals().subscribe(totalsObject => {
            this.total = totalsObject;
          });

          this.selectedCustomerService
            .getSelectedCustomer()
            .pipe(
              first(),
              switchMap(selectedCustomer =>
                forkJoin([
                  this.authService.getCurrentUser().pipe(first()),
                  of(selectedCustomer),
                  this.jeevesDeliveryAddressesService
                    .getOrderedDeliveryAddressesForCustomer(selectedCustomer.id)
                    .pipe(first()),
                  !!selectedCustomer.extra.kus.kundbetalarenr
                    ? this.customerService.getCustomer(selectedCustomer.extra.kus.kundbetalarenr).pipe(first())
                    : of(null),
                  this.creditBlockedService.checkCreditBlocked(selectedCustomer).pipe(first())
                ])
              )
            )
            .subscribe(a => {
              const user = a[0];
              const customer: Customer = a[1];
              this.deliveryAddresses = a[2];
              const customerPayer: Customer = a[3];
              this.isCreditHardBlocked = a[4].status === 'CREDIT_HARD_BLOCK';

              this.selectedCustomer = customer;
              this.additionalCostsTermsObject = this.additionalCostsService.getAdditionalCostTerms(
                this.selectedCustomer
              );
              this.freight = this.additionalCostsTermsObject.freight;
              this.minOrderQty = this.additionalCostsTermsObject.minOrderQty;
              this.adminCharge = this.additionalCostsTermsObject.adminCharge;
              this.franco = this.additionalCostsTermsObject.franco;
              this.freightMin = this.additionalCostsTermsObject.freightMin;
              this.adminChargeMin = this.additionalCostsTermsObject.adminChargeMin;

              //this.getValuesFromQHlTpSalesmarket(customer, environment.mainCountry.toUpperCase());
              if (customer.extra && customer.extra.kus) {
                this.isCardPaymentCustomer = customer.extra.kus.betkod === '99' || customer.extra.kus.betkod === 'CC';
              }

              this.mapUserCustomer(user, customer, customerPayer).subscribe(() => {
                if (user.extra.readOnlyPrice && (this.isPortalManagers || this.isCurrysPortal)) {
                  this.readOnlyPrice = user.extra.readOnlyPrice;
                }
                this.checkoutForms = this.getCheckoutForms(this.checkoutObject);
                if (this.checkoutObject.extra && this.checkoutObject.extra.oh) {
                  this.isCardPaymentCustomer =
                    this.checkoutObject.extra.oh.betkod === '99' || this.checkoutObject.extra.oh.betkod === 'CC';
                }
                this.initStatus = CheckoutInitStates.Completed;
                if (this.isNOCustomerPortal && this.total.totalValue < this.adminChargeMin && this.adminCharge > 0) {
                  this.modalService
                    .openConfirmYesNoModal(
                      undefined,
                      'Orderverdien er under minstegrensen for fri service og ' + this.adminCharge + ' NOK tilkommer.',
                      { size: 'sm' },
                      'Bekrefte',
                      null
                    )
                    .then(confirmed => {
                      if (!confirmed) {
                        this.location.back();
                      }
                    });
                }
              });
            });
        }

        this.setAnyCartItemRequestQtyAboveAvailable(cart);
      });

    // this.getValuesFromQHlTpSalesmarket();
  }

  getValuesFromQHlTpSalesmarket(customer: Customer, country: string) {
    const qHlTpSalesmarket = this.metadataService.getMetadataTableList('q_hl_tp_salesmarket');
    if (qHlTpSalesmarket) {
      const salesmarket =
        qHlTpSalesmarket.find(
          s =>
            s.q_salesmarket_code === (customer.extra.kus.q_salesmarket_code || country) &&
            s.q_supergrpcode === (customer.extra.kus.q_supergrpcode || null)
        ) ||
        qHlTpSalesmarket.find(
          s =>
            s.q_salesmarket_code === (customer.extra.kus.q_salesmarket_code || country) &&
            s.q_financialgrpcode === (customer.extra.kus.q_financialgrpcode || null)
        );
      this.freight = Number(salesmarket?.q_hl_fc_amount || 0);
      this.minOrderQty = Number(salesmarket?.q_hl_minordvalue || 0);
      this.adminCharge = Number(salesmarket?.q_hl_sof_charged_amount || 0);
      this.franco = Number(salesmarket?.q_hl_fc_threshold_amount || 0);

      this.freightMin = Number(salesmarket?.q_hl_fc_threshold_amount || 0);
      this.adminChargeMin = Number(salesmarket?.q_hl_sof_threshold_amount || 0);
    }
  }

  onDeliveryAddressSelected(index: any) {
    this.deliveryAddressesSelectedIndex = index;
    const jeevesOrderDeliveryAddress = this.deliveryAddresses[index];

    Object.keys(jeevesOrderDeliveryAddress.addressValues).forEach(key => {
      this.checkoutForms[0].get('procargs').get(key).patchValue(jeevesOrderDeliveryAddress.addressValues[key]);
    });
  }

  setAnyCartItemRequestQtyAboveAvailable(cart: CartRow[]): void {
    this.anyCartItemRequestQtyAboveAvailable =
      cart.filter(
        cartRow =>
          !!cartRow.extra.validationData &&
          !!cartRow.extra.validationData.availabilityData &&
          cartRow.extra.validationData.availabilityData.currentAvailability < cartRow.qty
      ).length > 0;
  }

  getMinValueToOrder(): number {
    if (this.isFRCustomerPortal1600General) {
      return this.minOrderQty;
    }

    if (this.isFRCustomerPortalPharma) {
      return 100;
    }

    if (this.isCurrysPortal) {
      this.minOrderQty = 40;
      return 40;
    }

    // no min value for customer NO portal or pharma
    if (
      this.isNOCustomerPortal ||
      this.isCoopPortal ||
      this.isManagersNisa ||
      this.isKeskoCustomer ||
      this.isCustNlSuperunie1600
    ) {
      return 0;
    }

    // default
    return 50;
  }

  private mapUserCustomer(user: User, customer: Customer, customerPayer: Customer): Observable<any> {
    if (customer == null) {
      alert('The customer received was not valid. Please contact your administrator');
      throw new Error('No customer received');
    }

    // CUSTOMER PORTALS
    // If the store is connected to a payer ( kus.kundbetalarenr ), then show the address from the Payer from the fr table:
    // If No Payer then use customer address from fr table:
    if (!this.isSales) {
      if (!!customerPayer) {
        this.billingCustomer = customerPayer.extra.fr;
      } else {
        this.billingCustomer = customer.extra.fr;
      }
    } else {
      this.billingCustomer = customer.extra._billing;
    }

    this.deliveryCustomer = customer;
    this.checkoutObject.deliveryCustomerId = customer.id;

    this.checkoutObject.extra.oh = {};
    this.checkoutObject.extra.oh.betkod = this.deliveryCustomer.extra.kus.betkod;
    this.checkoutObject.extra.oh.extordtyp = environment.externalOrderType;
    const isCustNlSuperunie1600 =
      !environment.sales &&
      environment.bolag === '1600' &&
      environment.mainCountry === 'nl' &&
      environment.mainCustomer === 'spar';

    if (isCustNlSuperunie1600) {
      this.checkoutObject.extra.oh.kundref2 = user.email;
    }
    this.checkoutObject.extra.procargs = {};

    this.checkoutObject.extra.procargs.levsattkod = this.deliveryCustomer.extra.kus.levsattkod;
    this.checkoutObject.extra.procargs.levvillkkod = this.deliveryCustomer.extra.kus.levvillkkod;

    if (!this.deliveryCustomer.extra.kus.kundbetalarenr) {
      this.checkoutObject.billingCustomerId = this.deliveryCustomer.extra.kus.id;
    } else {
      this.checkoutObject.billingCustomerId = this.deliveryCustomer.extra.kus.kundbetalarenr;
    }

    if (!this.getDisableEmail()) {
      this.checkoutObject.extra.confirmationEmail = user.email || '';
    }

    if (!this.isSales && !!this.deliveryAddresses && this.deliveryAddresses.length > 0) {
      this.isDeliveryInformationFromDeliveryAddresses = true;
      this.checkoutObject.extra.procargs.ordlevadr1 = this.deliveryAddresses[0].addressValues.ordlevadr1;
      this.checkoutObject.extra.procargs.ordlevadr2 = this.deliveryAddresses[0].addressValues.ordlevadr2;
      this.checkoutObject.extra.procargs.ordlevadr3 = this.deliveryAddresses[0].addressValues.ordlevadr3;
      this.checkoutObject.extra.procargs.ordlevadr4 = this.deliveryAddresses[0].addressValues.ordlevadr4;
      this.checkoutObject.extra.procargs.ftgpostnr = this.deliveryAddresses[0].addressValues.ftgpostnr;
      this.checkoutObject.extra.procargs.ordlevadrlandskod = this.deliveryAddresses[0].addressValues.ordlevadrlandskod;
      this.checkoutObject.extra.procargs.ordlevplats1 = this.deliveryAddresses[0].addressValues.ordlevplats1;
    } else {
      // use regular delivery customer
      this.checkoutObject.extra.procargs.ordlevadr2 = this.deliveryCustomer.extra.fr.ftgpostadr1;
      this.checkoutObject.extra.procargs.ordlevadr3 = this.deliveryCustomer.extra.fr.ftgpostadr2;
      this.checkoutObject.extra.procargs.ordlevadr4 = this.deliveryCustomer.extra.fr.ftgpostadr3;
      this.checkoutObject.extra.procargs.ftgpostnr = this.deliveryCustomer.extra.fr.ftgpostnr;
      this.checkoutObject.extra.procargs.ordlevadrlandskod = this.deliveryCustomer.extra.fr.landskod;
      this.checkoutObject.extra.procargs.ordlevadrprovincecode = this.deliveryCustomer.extra.fr.provincecode;
    }

    /*
      This should be done in all checkouts, but unsure of consequences right now
    */
    if (user.extra.orderParams) {
      merge(this.checkoutObject.extra, user.extra.orderParams);
    }
    return of(null);
  }

  resetScroll() {
    window.scrollTo(0, 0);
  }

  public setDiscountDescription(desc: string) {
    this.discountDescription = desc;
  }

  protected getCheckoutForms(checkoutObject: any): FormGroup[] {
    let obj: { [key: string]: any } = {
      oh: this.formBuilder.group({
        editext: ['']
      }),
      procargs: this.formBuilder.group({
        ordlevadr1: [
          { value: checkoutObject.extra.procargs.ordlevadr1, disabled: this.isDeliveryInformationFromDeliveryAddresses }
        ],
        ordlevadr2: [
          { value: checkoutObject.extra.procargs.ordlevadr2, disabled: this.isDeliveryInformationFromDeliveryAddresses }
        ],
        ordlevadr3: [
          { value: checkoutObject.extra.procargs.ordlevadr3, disabled: this.isDeliveryInformationFromDeliveryAddresses }
        ],
        ordlevadr4: [
          { value: checkoutObject.extra.procargs.ordlevadr4, disabled: this.isDeliveryInformationFromDeliveryAddresses }
        ],
        ftgpostnr: [
          { value: checkoutObject.extra.procargs.ftgpostnr, disabled: this.isDeliveryInformationFromDeliveryAddresses }
        ],
        ordlevadrlandskod: [
          {
            value: checkoutObject.extra.procargs.ordlevadrlandskod,
            disabled: this.isDeliveryInformationFromDeliveryAddresses
          }
        ],
        ordlevplats1: [checkoutObject.extra.procargs.ordlevplats1]
      })
    };

    if (this.isNOCustomerPortal) {
      obj.oh.addControl('kundref2', this.formBuilder.control(''));
    } else {
      obj.oh.addControl('kundbestnr', this.formBuilder.control(''));
    }

    if (!this.getDisableEmail()) {
      obj = {
        ...obj,
        confirmationEmail: [checkoutObject.extra.confirmationEmail]
      };
    }

    return [this.formBuilder.group(obj)];
  }

  getIsShippinInfoStepValid(): boolean {
    // disable all erros
    this.showKundref2Error = false;

    if (this.isNOCustomerPortal) {
      const kundref2 = this.checkoutForms[0].controls.oh.get('kundref2').value;
      if (!kundref2) {
        this.showKundref2Error = true;
        return false;
      }
    }

    return true;
  }

  getIsKundbestnrValid(): boolean {
    // disable all erros
    this.showKundbestnr2Error = false;

    if (this.isCurrysPortal) {
      const kundbestnr = this.checkoutForms[0].controls.oh.get('kundbestnr').value;
      if (!kundbestnr) {
        this.showKundbestnr2Error = true;
        return false;
      }
    }

    return true;
  }

  protected mapCheckoutFormsTosubmitBody(checkoutForms: FormGroup[], submitBody: any) {
    checkoutForms.forEach(form => {
      submitBody.extra.procargs = {
        ...submitBody.extra.procargs,
        ...form.getRawValue().procargs
      };
      submitBody.extra.oh = {
        ...submitBody.extra.oh,
        ...form.getRawValue().oh
      };
      if (this.campaignCode && this.campaignCode !== '') {
        submitBody.extra.discountCode = this.campaignCode;
      }
      submitBody.extra.confirmationEmails = [];
      if (!this.getDisableEmail()) {
        submitBody.extra.confirmationEmails[0] = form.getRawValue().confirmationEmail;
      }
    });
  }

  submit(): void {
    this.cartSubscribe.unsubscribe();
    this.submitting = true;

    this.cartService
      .getCurrentCart()
      .pipe(first())
      .subscribe(cartRows => {
        const mappedRows = cartRows.map(row => {
          const res = {
            id: row.productId,
            quantity: row.qty,
            extra: {
              ...row.extra
            }
          };
          if (res.extra && res.extra.orp && !res.extra.orp.lagstalle) {
            res.extra.orp.lagstalle = row.targetStockId;
          }

          if (res.extra && res.extra.validationData) {
            delete res.extra.validationData;
          }

          return res;
        });
        this.checkoutObject.rows = mappedRows;
        this.checkoutObject.extra.oh.q_earlier = 1;

        this.mapCheckoutFormsTosubmitBody(this.checkoutForms, this.checkoutObject);

        this.checkoutObject.extra.confirmationEmails = this.checkoutObject.extra.confirmationEmails || [];
        const email = environment.quoteConfirmationEmailAddress;
        if (email) {
          if (this.checkoutObject.extra.confirmationEmails.indexOf(email) < 0) {
            this.checkoutObject.extra.confirmationEmails.push(email);
          }
        }

        // add adminFee and freightCarge if needed
        this.backendFeatureService.isActivated('trade-policy').subscribe(active => {
          if (active) {
            this.addFreightCost();
            this.addAdminFeeCost();
          } else {
            // Legacy cases below
            if (this.isNOCustomerPortal && this.adminCharge > 0) {
              this.addAdminFeeCost();
            }
            if (this.isNOCustomerPortal && this.freight > 0 && !this.isCurrysPortal) {
              this.addFreightCost();
            }
          }
        });

        this.checkoutObject.extra[this.hlDisplayMsflowArgumentService.getArgumentKey()] =
          this.hlDisplayMsflowArgumentService.createMsFlowArguments(ActionType.Order);

        if ((this.isFRCustomerPortalPharma || this.isFRCustomerPortal1600General) && this.isCardPaymentCustomer) {
          this.submitStripeFormPayment();
        } else {
          if (this.isCreditHardBlocked) {
            const options: NgbModalOptions = {
              backdrop: 'static',
              size: 'sm',
              keyboard: true
            };
            const translation = this.creditBlockedService.getPortalTranslationCustomer(this.selectedCustomer);
            this.modalService.openBasicModal(translation.title, translation.body, options).then(
              result => {},
              reason => {}
            );
          } else {
            this.postOrder(this.checkoutObject);
          }
        }
      });
  }

  public setSelectedDeliveryDate(date: CalendarDate) {
    if (!this.checkoutObject.extra.oh) {
      this.checkoutObject.extra.oh = {};
    }
    this.checkoutObject.extra.oh.q_ordberlevdat_cust = date.date;
    if (environment.bolag !== '1210') {
      this.checkoutObject.extra.oh.q_ordbeglevdat_cust = date.date;
    }

    if ((date as any).estimatedShippingDate) {
      this.checkoutObject.extra.oh.ordberlevdat = (date as any).estimatedShippingDate;
      if (environment.bolag !== '1210') {
        this.checkoutObject.extra.oh.ordbeglevdat = (date as any).estimatedShippingDate;
      }

      this.cartService
        .getCurrentCart()
        .pipe(first())
        .subscribe(rows => {
          rows.forEach(row => {
            this.setDeliveryDate(row, this.checkoutObject.extra.oh.ordberlevdat);
          });
        });
    }
  }

  protected async postOrder(submitBody: any) {
    this.submittedCart = await this.generateReceipt();
    this.http.post<any>('json/order/create', submitBody).subscribe(res => {
      this.cartService.clearCart();
      this.submitting = false;
    });
  }

  private async generateReceipt(): Promise<CartRow[]> {
    const receipt = await this.cartService.getCurrentCart().pipe(first()).toPromise();
    return receipt;
  }

  public setDeliveryDate(cartRow: CartRow, deliveryDate: Date): void {
    // Clone the extra object if it exists, or create a new one if it doesn't
    const extra = cartRow.extra ? { ...cartRow.extra } : {};
    // Clone the orp object if it exists, or create a new one if it doesn't
    extra.orp = extra.orp ? { ...extra.orp } : {};

    // Set the ordberlevdat property on the cloned orp object
    extra.orp.ordberlevdat = this.getFormattedDateString(deliveryDate);
    // Pass the cloned and modified extra object to the cart service
    this.cartService.setExtra(cartRow.productId, extra, cartRow.targetStockId, cartRow.productPartialId);
  }

  // Require format yyMMdd.
  public getStandardizedDateString(date: string): string {
    const res = '20' + date.substring(0, 2) + '-' + date.substring(2, 4) + '-' + date.substring(4);
    return res;
  }

  public getFormattedDateString(date: Date): string {
    let dateString = '';
    dateString = dateString + date.getFullYear();

    const month = '' + (date.getMonth() + 1);
    if (month.length === 1) {
      dateString = dateString + '-' + '0' + month;
    } else {
      dateString = dateString + '-' + month;
    }

    const day = '' + date.getDate();
    if (day.length === 1) {
      dateString = dateString + '-' + '0' + day;
    } else {
      dateString = dateString + '-' + day;
    }
    return dateString;
  }

  getSubmitButtonText(): string {
    if (this.isFRCustomerPortalPharma) {
      return this.translateService.instant('CONFIRM_ORDER_PHARMA_PORTAL');
    }

    return this.translateService.instant('CONFIRM_ORDER');
  }

  async submitStripeFormPayment() {
    if (!environment.stripeApiKey) {
      throw new Error('Stripe Api Key undefined');
    }

    const stripe = await loadStripe(environment.stripeApiKey);

    this.checkoutObject.extra = {
      ...this.checkoutObject.extra,
      _urlOrigin: location.origin,
      _locale: this.stripeService.getLocale()
    };

    this.stripeService.createSession(this.checkoutObject).subscribe(sessionResult => {
      if (!sessionResult) {
        throw new Error('Invalid Stripe session');
      }

      stripe.redirectToCheckout({ sessionId: sessionResult.id }).then(
        paymentResult => {
          console.log('paymentResult', paymentResult);
        },
        reason => {
          console.log('payment failed', reason);
          this.router.navigateByUrl('payment-failure');
        }
      );
    });
  }

  private addFreightCost(): void {
    this.additionalCostsService.addFreightChargeToCheckout(
      this.checkoutObject,
      this.additionalCostsTermsObject,
      this.getSelectedCustomerCurrencyCode()
    );
  }

  private addAdminFeeCost(): void {
    this.additionalCostsService.addAdminFeeToCheckout(
      this.checkoutObject,
      this.additionalCostsTermsObject,
      this.getSelectedCustomerCurrencyCode()
    );
  }

  private getSelectedCustomerCurrencyCode(): string {
    if (!!this.selectedCustomer.extra && this.selectedCustomer.extra.kus) {
      return this.selectedCustomer.extra.kus.valkod || '';
    }

    return '';
  }

  private setNoCustomerPortal(): void {
    const locationOrigin = location.origin || '';
    if (
      locationOrigin.indexOf('meny') > -1 ||
      locationOrigin.indexOf('joker') > -1 ||
      locationOrigin.indexOf('kiwi') > -1 ||
      locationOrigin.indexOf('spar') > -1
    ) {
      this.isNOCustomerPortalNorgesGroupAndSpar = true;
    } else if (locationOrigin.indexOf('rema') > -1) {
      this.isNOCustomerPortalReitanGroup = true;
    } else if (locationOrigin.indexOf('coop') > -1) {
      this.isNOCustomerPortalCoop = true;
    }
  }
}
